<template>
    <Spinner v-if="!loaded.profiles" />
    <div v-else>
        <div v-for="(profile, profile_i) in profiles" v-bind:key="profile"  class="border border-light border-2 rounded-3 shadow-sm ms-5 me-5 d-flex align-items-center">
            <h3 class="mt-2 mb-2 ms-2 flex-grow-1" @click="goto(profile.id)">{{profile.name}}</h3>
            <h4 class="mt-2 mb-2 me-3">{{formatSeconds(profile.freetime)}}</h4>
            <button class="btn btn-outline-primary me-2" data-bs-toggle="modal" :data-bs-target="'#profile'+profile_i.toString()">Edit</button>
            <Modal :id="'profile'+profile_i.toString()" :title="profile.name">
                <hr>
                <div class="float-end">
                    <small>Delete this task: </small>
                    <button class="btn btn-danger btn-sm" data-bs-toggle="modal" :data-bs-target="'#profile'+profile_i.toString()" @click="deleteProfile(profile.id)">Delete</button>
                </div>
            </Modal>
        </div>
        <Modal id="addProfile" title="Add profile">
            <form @submit.prevent="addProfile">
                <div class="input-group mb-3">
                    <span class="input-group-text">Name</span>
                    <input type="text" class="form-control" v-model="newProfile.name">
                </div>
                <div class="input-group mb-3">
                    <span class="input-group-text">Total time (s)</span>
                    <input type="number" class="form-control" v-model="newProfile.time">
                </div>
                <div class="input-group mb-3">
                    <label class="input-group-text">Reset interval</label>
                    <select class="form-select" v-model="newProfile.reset">
                        <option value="0">Daily</option>
                        <option value="1">Weekly</option>
                        <option value="2">Monthly</option>
                    </select>
                </div>
                <input type="submit" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#addProfile">
            </form>
        </Modal>
        <ActionButton data-bs-toggle="modal" data-bs-target="#addProfile"/>
    </div>
</template>

<style scoped>
</style>

<script>
 import {authFetch} from '@/lib/api.js'
 import Spinner from '@/components/Spinner.vue'
 import ActionButton from '@/components/ActionButton.vue'
 import Modal from '@/components/Modal.vue'
 import {code} from '@/lib/auth.js'
 
 export default {
     name: 'Profiles',
     components: {
         Spinner,
         ActionButton,
         Modal,
     },
     data(){
         return {
             loaded: {profiles: false},
             profiles: [],
             newProfile: {name: '', reset: 0, time: 0},
         }
     },
     methods: {
         goto(id){
             console.log(id)
             this.$router.push({name: 'timemgmtProfileDashboard', params: {profile: id}})
         },
         formatSeconds(secs){
             let d = secs;
             let h = Math.floor(d / 3600);
             let m = Math.floor(d % 3600 / 60);
             let s = Math.floor(d % 3600 % 60);
             let out = ""
             
             if (h>0){
                 if (h < 10){
                     out += '0'
                 }
                 out += h.toString()+":"
             }
             if (m < 10){
                 out += '0'
             }
             out += m.toString() + ":"
             if (s < 10){
                 out += '0'
             }
             out += s.toString()
             return out
         },
         refreshProfiles(){
             authFetch('/timemgmt/profiles', 'GET', {})
                 .then((response) => {
                     console.log(response)
                     this.profiles = response.data
                     for (let i=0; i<response.data.length; i++){
                         response.data[i].name = code.decryptMessage(response.data[i].name)
                     }
                     this.loaded.profiles = true
                     
                 })
                 .catch((error) => {
                     console.log(error.response)
                 })
         },
         deleteProfile(id){
             authFetch('/timemgmt/profile', 'DELETE', {profile: id})
                 .then((response) => {
                     this.refreshProfiles()
                 })
                 .catch((error) => {
                     console.log(error.response)
                 })
         },
         addProfile(id){
             authFetch('/timemgmt/profile', 'POST', {name: code.encryptMessage(this.newProfile.name), reset: this.newProfile.reset, freetime: this.newProfile.time})
                 .then((response) => {
                     this.refreshProfiles()
                 })
                 .catch((error) => {
                     console.log(error.response)
                 })
         }
     },
     beforeMount(){
         this.refreshProfiles()
     },
 }
</script>
